import React from "react";
import Modal from "components/shared/Modal";
import Input from "components/shared/Input";
import Select from "components/shared/Select";

class BeforeYouBeginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      iAmA: null,
      errorMessages: props.errorMessages,
    };
    this.setUsername = this.setUsername.bind(this);
    this.setIAmA = this.setIAmA.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit(e) {
    e.preventDefault();
    $.ajax({
      data: {
        user: {
          i_am_a: this.state.iAmA,
          ...(this.props.shouldShowUsernameInput && {
            username: this.state.username,
          }),
        },
      },
      dataType: "json",
      method: "PUT",
      url: this.props.beforeYouBeginUpdateUrl,
      success: (_) => {
        $("#before-you-begin-modal").modal("hide");
      },
      error: (error) => {
        this.setState({ errorMessages: error.responseJSON.errors });
      },
    });
  }

  setUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  setIAmA(option) {
    this.setState({
      iAmA: option.value,
    });
  }

  render() {
    const { errorMessages } = this.state;
    const { contactUrl, iAmAOptions } = this.props;
    return (
      <Modal
        id="before-you-begin-modal"
        title="Complete your profile"
        showOnMount
        backdropStatic
        footer={false}
      >
        <div className="text-center">
          <div className="title-4">Welcome!</div>
          <div dangerouslySetInnerHTML={{ __html: this.props.bannerMessage }} />
        </div>

        <form>
          {this.props.shouldShowUsernameInput ? (
            <Input
              name="user[username]"
              handleChange={this.setUsername}
              inputId="user_username"
              inputClass="input-lg"
              errorMessage={errorMessages.username}
              labelText="Username"
            />
          ) : null}
          <Select
            id="user_i_am_a"
            name="user[i_am_a]"
            onChange={this.setIAmA}
            baseClassName="lc-select-container"
            errorMessage={errorMessages.i_am_a}
            placeholder="Select one"
            options={iAmAOptions}
            labelText="I am a:"
          />
          <button
            type="submit"
            onClick={this.submit}
            className="btn btn-primary btn-block"
          >
            Get Started
          </button>

          <div className="body-2 text-center">
            Need help?&nbsp;
            <a href={contactUrl}>Contact us</a>.
          </div>
        </form>
      </Modal>
    );
  }
}

export default BeforeYouBeginModal;
