import React, { createRef, useCallback, useEffect, useState } from "react";
import { subscribe, unsubscribe } from "../../helpers/pubsub";
import Popover from "./Popover";

export default function BookmarksFeaturePopover({
  forceVisibility = false,
  hover = false,
}) {
  const [isVisible, setIsVisible] = useState(forceVisibility);
  const popoverRef = createRef();

  const dismiss = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  useEffect(() => {
    subscribe("displayBookmarksFeaturePopover", () => {
      setIsVisible(true);
    });

    return () => {
      unsubscribe("displayBookmarksFeaturePopover", () => {
        setIsVisible(true);
      });
    };
  }, []);

  return (
    <Popover
      ref={popoverRef}
      arrowX="right"
      containerClass="container dynamic"
      hover={hover}
      isVisible={isVisible}
      popoverClassName="bookmarks-feature-popover"
    >
      <div className="content">
        <div className="image" />
        <div className="text">
          <div>
            <strong>Your Saved Guides</strong>
            <span className="subcopy">New</span>
          </div>
          <div>
            Easily access all the guides you’ve saved in your account menu.
          </div>
        </div>
      </div>
      <button
        className="btn btn-default btn-block btn-lg"
        onClick={dismiss}
        type="button"
      >
        Got It
      </button>
    </Popover>
  );
}
