import React from "react";
import PropTypes from "prop-types";

export const menuItemType = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const MenuItem = ({ label, url }) => {
  return (
    <a className="nav-bar--child_item" href={url}>
      {label}
    </a>
  );
};

MenuItem.propTypes = menuItemType;

export default MenuItem;
