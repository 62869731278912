// Use this when having to create complicated inputs. See /dev/components/inputs for details

import React from "react";
import PropTypes from "prop-types";
import isString from "lodash/isString";

import InputErrorMessage from "components/shared/InputErrorMessage";
import LabelContainer from "components/shared/LabelContainer";

const propTypes = {
  autocomplete: PropTypes.string,
  autocorrect: PropTypes.string,
  autocapitalize: PropTypes.string,
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func,
  iconClass: PropTypes.string,
  InputComponent: PropTypes.oneOf(["input", "textarea"]),
  inputClass: PropTypes.string,
  inputId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

const defaultProps = {
  autocapitalize: "off",
  autocomplete: "off",
  autocorrect: "off",
  InputComponent: "input",
  errorMessage: null,
  handleChange: null,
  iconClass: null,
  inputClass: "input-lg",
  isDisabled: false,
  isRequired: false,
  placeholder: "",
  type: "text",
  value: "",
};

const Input = (props) => {
  const {
    autocapitalize,
    autocomplete,
    autocorrect,
    errorMessage,
    handleChange,
    inputId = props.name, // Default input ID to the name
    inputClass,
    InputComponent,
    isControlled,
    isDisabled,
    min,
    max,
    name,
    placeholder,
    step,
    type,
    value,
    ...labelProps
  } = props;

  const hasError = errorMessage !== null;
  const className = hasError ? `${inputClass} error` : inputClass;

  const hasIcon = isString(props.iconClass) && props.iconClass.length > 0;

  // If component is controlled externally, set isControlled to true and
  // be sure to pass a handleChange as well
  const valueProps = {};
  if (isControlled) {
    valueProps.value = value;
  } else {
    valueProps.defaultValue = value;
  }

  const onChange = (e) => {
    handleChange && handleChange(e);
  };

  return (
    <div className={`input-container ${hasIcon && "input-container--icon"}`}>
      {hasIcon && <i className={props.iconClass} />}
      <LabelContainer {...labelProps} hasError={hasError} name={inputId} />
      <InputComponent
        autoCapitalize={autocapitalize}
        autoComplete={autocomplete}
        autoCorrect={autocorrect}
        id={inputId}
        className={className}
        disabled={isDisabled}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        min={min}
        max={max}
        step={step}
        {...valueProps}
      />
      {errorMessage && <InputErrorMessage message={errorMessage} />}
    </div>
  );
};

Input.defaultProps = defaultProps;
Input.propTypes = propTypes;

export default Input;
