import React from "react";
import PropTypes from "prop-types";

import MaskInput from "react-maskinput";
import InputErrorMessage from "components/shared/InputErrorMessage";
import InputInfoMessage from "components/shared/InputInfoMessage";
import LabelContainer from "components/shared/LabelContainer";

const propTypes = {
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func,
  inputClass: PropTypes.string,
  inputId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  tokenize: PropTypes.bool,
  value: PropTypes.string,
};

const defaultProps = {
  errorMessage: null,
  handleChange: null,
  inputClass: "input-lg",
  isDisabled: false,
  isRequired: false,
  placeholder: "MM / DD / YYYY",
  showErrorMessage: true,
  value: "",
};

const BirthdayInput = (props) => {
  const mask = "00/00/0000";
  const maskString = "MM/DD/YYYY";

  const {
    errorMessage,
    handleChange,
    infoMessage,
    inputId = props.name, // Default input ID to the name
    inputClass,
    isDisabled,
    name,
    placeholder,
    showErrorMessage,
    tokenize,
    value,
    ...labelProps
  } = props;

  const hasError = errorMessage !== null;
  const className = hasError ? `${inputClass} error` : inputClass;

  const revealInfoMessage = (input) => {
    const $infoMessage = $(input).siblings(".input__info-message");
    input.value === "" ? $infoMessage.hide() : $infoMessage.show();
  };

  const tokenizeValue = (valueToTokenize) => {
    if (valueToTokenize[2]) {
      $(`#${inputId}\\(1i\\)`).val(parseInt(valueToTokenize[2]));
    }
    if (valueToTokenize[0]) {
      $(`#${inputId}\\(2i\\)`).val(parseInt(valueToTokenize[0]));
    }
    if (valueToTokenize[1]) {
      $(`#${inputId}\\(3i\\)`).val(parseInt(valueToTokenize[1]));
    }
  };

  const onChange = (e) => {
    tokenize && tokenizeValue(e.target.value.split("/"));
    infoMessage && revealInfoMessage(e.target);
    handleChange && handleChange(e);
  };

  return (
    <div className="input-container">
      <LabelContainer {...labelProps} hasError={hasError} name={name} />
      <MaskInput
        className={className}
        disabled={isDisabled}
        id={inputId}
        inputMode="numeric"
        mask={mask}
        maskString={maskString}
        name={name}
        onChange={onChange}
        pattern="[0-9]*"
        placeholder={placeholder}
        showMask={false}
        size={20}
        value={value}
      />
      {!errorMessage && infoMessage && (
        <InputInfoMessage message={infoMessage} />
      )}
      {showErrorMessage && errorMessage && (
        <InputErrorMessage message={errorMessage} />
      )}
    </div>
  );
};

BirthdayInput.defaultProps = defaultProps;
BirthdayInput.propTypes = propTypes;

export default BirthdayInput;
