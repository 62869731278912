import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import helpers from "helpers/base";
import { useMediaQuery } from "react-responsive";
import { SM_PIXEL_MAX, XXS_PIXEL_MAX } from "constants/breakpoints";
import LitCard from "./LitCard";

const propTypes = {
  initialDisplayAmount: PropTypes.number,
  guides: PropTypes.array,
  useSignedIn: PropTypes.bool,
};

const recentlyAddedSelector = ".recently-added";

export default function BookmarkList({
  initialDisplayAmount = 3,
  includeTags = false,
  guides,
  userSignedIn,
}) {
  const [displayAmount, setDisplayAmount] = useState(0);

  //* Handling Screen Sizes / Layouts Programmatically *//
  const handleDesktopLayoutChange = (matches) => {
    if (matches) {
      setDisplayAmount(initialDisplayAmount);
    }
  };

  const handleMediumLayoutChange = (matches) => {
    if (matches) {
      setDisplayAmount(4);
    }
  };

  const handleMobileLayoutChange = (matches) => {
    if (matches) {
      setDisplayAmount(3);
    }
  };

  const isDesktopLayout = useMediaQuery(
    { minWidth: SM_PIXEL_MAX },
    undefined,
    handleDesktopLayoutChange
  );
  useMediaQuery(
    { minWidth: XXS_PIXEL_MAX, maxWidth: SM_PIXEL_MAX },
    undefined,
    handleMediumLayoutChange
  );
  const isMobileLayout = useMediaQuery(
    { maxWidth: XXS_PIXEL_MAX },
    undefined,
    handleMobileLayoutChange
  );

  // Prior to painting the DOM, we want to set the display amount based
  // on screen size
  useLayoutEffect(() => {
    if (isDesktopLayout) {
      setDisplayAmount(initialDisplayAmount);
    } else if (isMobileLayout) {
      setDisplayAmount(3);
    } else {
      setDisplayAmount(4);
    }
  }, []);

  //* ============== *//

  const [canShowMore, setCanShowMore] = useState(guides.length > displayAmount);
  const showMoreAmount = guides.length - displayAmount;
  const showMoreText = canShowMore
    ? `Browse ${showMoreAmount} more`
    : "View less";
  const visibleGuides = canShowMore ? guides.slice(0, displayAmount) : guides;

  const toggleShowMore = () => {
    if (!canShowMore) {
      const container = document.querySelector(recentlyAddedSelector);
      helpers.dom.scrollTo(container);
    }

    setCanShowMore(!canShowMore);
  };

  return (
    <div className="recently-added__original">
      <div className="guide-list">
        <div className="guide-list__groups-wrapper">
          <ul className="guide-list__groups guide-list__groups--grid">
            <li className="guide-list__group">
              <ul className="guide-list__guides">
                <div className="row">
                  {visibleGuides.map((guide, index) => (
                    <div
                      className="col-xxs-12 col-xs-6 col-sm-6 col-md-4 col-lg-4"
                      key={guide.slug}
                    >
                      <li className="guide-list__guide">
                        <LitCard
                          {...guide}
                          includeTags={includeTags}
                          index={index}
                          userSignedIn={userSignedIn}
                        />
                      </li>
                    </div>
                  ))}
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {guides.length > 3 && (
        <button
          className="btn btn-md btn-default recently-added__more-btn"
          onClick={toggleShowMore}
          type="button"
        >
          {showMoreText}
        </button>
      )}
    </div>
  );
}

BookmarkList.propTypes = propTypes;
