import React from "react";

import { toast } from "react-toastify";

export const defaultToastConfig = {
  position: toast.POSITION.BOTTOM_CENTER,
  theme: "dark",
  icon: false,
  autoClose: 4000, // ms
  closeOnClick: false,
  draggable: false,
  toastTimeout: 300, // ms
};

export const toastNotify = (message, extraContent = false) => {
  toast.dismiss();
  setTimeout(
    () =>
      toast(
        <div className="Toastify__toast-contents">
          <span className="Toastify__message-content">{message}</span>
          {!!extraContent && (
            <span className="Toastify__extra-content">{extraContent}</span>
          )}
        </div>,
        defaultToastConfig
      ),
    defaultToastConfig.toastTimeout
  );
};
