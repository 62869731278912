import React from "react";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";
import MenuParent, { menuParentProps } from "./MenuParent";

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(menuParentProps)),
};

const Menu = ({ items }) => {
  return (
    <div className="nav-bar--container">
      {items.map((item) => {
        return (
          <div key={item.label} className="nav-bar--items">
            {item.children?.length > 0 ? (
              <MenuParent {...item} />
            ) : (
              <MenuItem label={item.label} url={item.url} />
            )}
          </div>
        );
      })}
    </div>
  );
};

Menu.propTypes = propTypes;

export default Menu;
