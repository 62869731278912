import helpers from "helpers/base";

export default {
  randomizeBackgrounds(selector, keepCurrent = false) {
    const currentSelector = keepCurrent
      ? `${selector}:not([data-indentation-bg-style])`
      : selector;
    const cards = Array.from(document.querySelectorAll(currentSelector));
    const indices = cards.map((element) =>
      parseInt(element.getAttribute("data-index"))
    );
    const uniqueIndices = helpers.array.unique(indices).sort();
    let lastStyle = null;

    const getSelectorForLitCardWithIndex = (index) =>
      `${currentSelector}[data-index='${index.toString()}']`;

    // randomize every 12 litcards to a different style and offset
    // ensure the same style doesn't repeat twice by keeping track of the last style
    helpers.array.eachByN(uniqueIndices, 12, (array) => {
      let styles = helpers.array.shuffle([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
      ]);
      while (styles[0] === lastStyle) {
        styles = helpers.array.shuffle(styles);
      }
      helpers.array.each(array, (index) => {
        const cardSelector = getSelectorForLitCardWithIndex(index);
        const elements = Array.from(document.querySelectorAll(cardSelector));
        const style = styles.shift();
        const offset = Math.floor(Math.random() * 8);
        elements.forEach((element) => {
          element.setAttribute("data-indentation-bg-style", style);
          element.setAttribute("data-indentation-bg-offset", offset);
        });
        if (styles.length === 0) {
          lastStyle = style;
        }
      });
    });
  },
};
