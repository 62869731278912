import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import BookmarkButton from "../bookmarks/BookmarkButton";
import litCards from "../../views/shared/litCards";

const propTypes = {
  authorName: PropTypes.string,
  bookmarkId: PropTypes.string,
  bookmarkableType: PropTypes.string,
  hideSuccessCta: PropTypes.bool,
  id: PropTypes.number,
  index: PropTypes.number,
  includeTags: PropTypes.bool,
  onRemoval: PropTypes.func,
  randomizeBackgrounds: PropTypes.bool,
  summary: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  userSignedIn: PropTypes.bool.isRequired,
};

const defaultProps = {
  authorName: null,
  bookmarkId: null,
  hideSuccessCta: false,
  includeTags: false,
  index: 0,
  onRemoval: null,
  randomizeBackgrounds: true,
  summary: "",
  tags: [],
};

export default function LitCard({
  authorName,
  bookmarkableType,
  bookmarkId,
  hideSuccessCta,
  id,
  includeTags,
  index,
  onRemoval,
  randomizeBackgrounds,
  summary = "",
  tags,
  title,
  url,
  userSignedIn,
}) {
  useEffect(() => {
    if (randomizeBackgrounds) {
      litCards.randomizeBackgrounds(".lit-card", true);
    }
  }, []);

  const sanitizedSummary = (summary || "").replace(/<\/?[^>]+(>|$)/g, "");
  const truncatedSummary = sanitizedSummary.substring(0, 200);

  const tagColor = (tag) => {
    switch (tag) {
      case "Shakescleare":
        return "purple-tint-25";
      case "Lit Term":
        return "green-tint-25";
      default:
        return "blue-tint-35";
    }
  };

  const handleCardClick = (event) => {
    // (alan) Predicate is to ensure that we're not clicking
    // on the bookmark button within the div--handles
    // both mouse click and keypress events. stopPropagation()
    // in BookmarkButton only worked for mouse click but not keypress
    if (event.currentTarget === event.target) {
      window.location.href = url;
    }
  };

  return (
    <div
      className={classnames("lit-card", { "lit-card--no-tags": !tags.length })}
      data-index={index}
      onClick={handleCardClick}
      onKeyPress={handleCardClick}
      role="link"
      tabIndex={0}
    >
      <div className="lit-card__indentation" />
      <div className="lit-card__content-wrapper">
        <div className="lit-card__content">
          {!!tags.length && includeTags && (
            <div className="lit-card__tags">
              {tags.map((tag) => (
                <span
                  className={`lit-card__tag subtitle-2 ${tagColor(tag)}`}
                  key={`${index}-${tag}`}
                >
                  {tag}
                </span>
              ))}
            </div>
          )}
          <div className="title-6">{title}</div>
          {!!authorName && <div className="body-2">{`by ${authorName}`}</div>}
          <div className="body-3">{truncatedSummary}</div>
        </div>
        <div className="lit-card__fader" />
      </div>
      <div className="lit-card__bookmark">
        <BookmarkButton
          amplitudeContext="CardComponent"
          buttonClass="btn-borderless-alt"
          bookmarkableId={id}
          bookmarkableType={bookmarkableType}
          hideSuccessCta={hideSuccessCta}
          initialBookmarkId={bookmarkId}
          onRemoval={onRemoval}
          userSignedIn={userSignedIn}
        />
      </div>
    </div>
  );
}

LitCard.propTypes = propTypes;
LitCard.defaultProps = defaultProps;
