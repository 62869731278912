import React, { createRef, useCallback, useState } from "react";
import Popover from "./Popover";

export default function AskAIPopover({ arrowX = "right" }) {
  const [isVisible, setIsVisible] = useState(true);
  const popoverRef = createRef();

  const dismiss = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return (
    <Popover
      ref={popoverRef}
      arrowX={arrowX}
      containerClass="container dynamic"
      isVisible={isVisible}
    >
      <div className="content">
        <div className="text">
          <div>
            <strong>Ask LitCharts AI</strong>
            <span className="subcopy">New</span>
          </div>
          <div>
            Get instant answers from the only tool that combines LitCharts
            literary analysis with the power of AI.
          </div>
        </div>
      </div>
      <button
        className="btn btn-default btn-block btn-lg"
        onClick={dismiss}
        type="button"
      >
        Got It
      </button>
    </Popover>
  );
}
