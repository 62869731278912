import React, { createRef, useCallback, useState } from "react";
import Popover from "./Popover";

export default function AIToolsPopover({ arrowX = "left", hover = false }) {
  const [isVisible, setIsVisible] = useState(true);
  const popoverRef = createRef();

  const dismiss = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return (
    <Popover
      ref={popoverRef}
      arrowX={arrowX}
      containerClass="container dynamic"
      isVisible={isVisible}
      hover={hover}
      popoverClassName="ai-tools-popover"
    >
      <div className="content">
        <div className="image" />
        <div className="text">
          <div>
            <strong className="title">AI Tools</strong>
            <span className="subcopy">New</span>
          </div>
          <div className="body-2">
            Tools to make learning and teaching easier
          </div>
        </div>
      </div>
      <button
        className="btn btn-default btn-block btn-lg"
        onClick={dismiss}
        type="button"
      >
        Got It
      </button>
    </Popover>
  );
}
