import React, { createRef, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import Popover from "./Popover";

const sessionStorageKey = "highlightsAndNotesPopoverViewed";

export default function HighlightsAndNotesPopover({
  delayInSeconds = 1,
  arrowX,
  arrowY,
  forceVisibility = false,
}) {
  const hasViewedPopover = !!sessionStorage.getItem(sessionStorageKey);
  const [isVisible, setIsVisible] = useState(forceVisibility);
  const popoverRef = createRef();

  const dismiss = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const searchBtn = document.querySelector(
    ".header-controls__toggleable-search-control"
  );
  const containerClasses = classnames("container", {
    extended: !searchBtn && popoverRef,
  });

  useEffect(() => {
    setTimeout(() => {
      if (!hasViewedPopover) {
        setIsVisible(true);
        sessionStorage.setItem(sessionStorageKey, "true");
      }
    }, delayInSeconds * 1000);
  }, []);

  return (
    <Popover
      ref={popoverRef}
      arrowX={arrowX}
      arrowY={arrowY}
      containerClass={containerClasses}
      isVisible={isVisible}
      popoverClassName="highlights-and-notes-popover"
    >
      <div className="content">
        <div className="image" />
        <div className="text">
          <div>
            <strong>Highlights &amp; Notes</strong>
          </div>
          <div>
            Once you&apos;ve saved your first highlight or note, you can access
            it through your account menu.
          </div>
        </div>
      </div>
      <button
        className="btn btn-default btn-block btn-lg"
        onClick={dismiss}
        type="button"
      >
        Got It
      </button>
    </Popover>
  );
}
