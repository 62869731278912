import React, { useRef, useState } from "react";
import Checkbox from "components/shared/Checkbox";
import Modal from "components/shared/Modal";
import BirthdayInput from "components/shared/BirthdayInput";
import Select from "./Select";
import VariantCountrySelect from "../sell/VariantCountrySelect";

// TODO: Replace hardcoded paths with routes-generated values.
const SignUpModal = (props) => {
  const [fullErrorMessages, _setFullErrorMessages] = useState(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const modal = useRef(null);
  const form = useRef(null);
  const { signupOrigin } = props;

  // TODO: DRY up with User::I_AM_A constant.
  const iAmAOptions = [
    { value: "student", label: "Student" },
    { value: "teacher_educator", label: "Teacher / Educator" },
    { value: "parent", label: "Parent of Student" },
    { value: "librarian", label: "Librarian" },
    { value: "general_interest_reader", label: "General-interest Reader" },
  ];

  // TODO: Avoid useRef/useState dance throughout this.
  // when using state in functional components, only the initial state will be
  // available at the time event listeners are created, so we create refs and
  // functions that update them (perhaps messier than just using a react class component)
  const fullErrorMessagesRef = useRef(fullErrorMessages);
  const setFullErrorMessages = (data) => {
    const messages = Object.values(data).flat();
    fullErrorMessagesRef.current = messages;
    _setFullErrorMessages(messages);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (submitInProgress) {
      return;
    }
    setSubmitInProgress(true);
    $.ajax({
      data: $(form.current).serialize(),
      dataType: "json",
      method: "POST",
      url: "/",
      success: (_data, _textStatus, jqXHR) => {
        window.location.href = jqXHR.getResponseHeader("Location");
      },
      error: (jqXHR, _textStatus, _errorThrown) => {
        setSubmitInProgress(false);
        setFullErrorMessages(jqXHR.responseJSON.errors);
      },
    });
  };

  return (
    <Modal
      ref={modal}
      id="sign-up-modal"
      title="Sign up for a free&nbsp;account"
      showOnMount
      footer={false}
      onHidden={window.LitCharts.modal.mutex.unlock}
      backdropStatic // Prevents clicks on backdrop from closing modal.
      closeIcon
    >
      <div>
        <form noValidate ref={form} onSubmit={onSubmit} className="modal__form">
          {fullErrorMessages && (
            <ul className="error-list">
              {fullErrorMessages.map((message) => (
                <li>{message}</li>
              ))}
            </ul>
          )}

          {props.countryCode ? (
            <input
              type="hidden"
              name="user[country_code]"
              id="user_country_code"
              value={props.countryCode}
            />
          ) : (
            <div className="modal__form-group">
              <VariantCountrySelect
                baseClassName="lc-select-container--md"
                name="user[country_code]"
                id="user_country_code"
                placeholder="Select one"
                labelText="Country"
                options={props.countryOptions}
              />
            </div>
          )}

          {props.gdpr || !props.countryCode ? (
            <div className="modal__form-group">
              <BirthdayInput
                inputId="user_birthday"
                name="user[birthday]"
                labelText="Birthday"
                inputClass="input-md"
                tokenize
                infoMessage="Please enter as MM/DD/YYYY"
              />
            </div>
          ) : null}

          <div className="modal__form-group">
            <Select
              id="user_i_am_a"
              name="user[i_am_a]"
              baseClassName="lc-select-container--md"
              placeholder="Select one"
              options={iAmAOptions}
              labelText="I am a&hellip;"
            />
          </div>

          <div
            className={`marketing-optin-checkbox ${props.gdpr ? "" : "hidden"}`}
          >
            <Checkbox
              label="Get email updates when we add new guides and features. Unsubscribe at any time." // eslint-disable-line max-len
              name="user[email_marketing_optin]"
              checked={false}
            />
          </div>

          <input name="signup_origin" value={signupOrigin} type="hidden" />
          <button
            className="btn btn-primary btn-lg"
            type="submit"
            disabled={submitInProgress}
          >
            Create Account
          </button>
        </form>
        <div className="text-center">
          Already have an account? <a href="/sign-in">Sign in</a>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
