import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export default class Modal extends React.Component {
  static propTypes = {
    closeIcon: PropTypes.bool,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.string,
    onHidden: PropTypes.func,
    showOnMount: PropTypes.bool,
    showAfterDelay: PropTypes.number,
    backdropStatic: PropTypes.bool, // Set to true to prevent clicks on backdrop from closing modal.
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    extraClassName: PropTypes.string,
    showAtParam: PropTypes.string,
  };

  static defaultProps = {
    closeIcon: false,
    footer: (
      <button
        className="btn btn-default btn-md"
        data-dismiss="modal"
        type="button"
      >
        Close
      </button>
    ),
    id: "",
    showOnMount: false,
    backdropStatic: false,
    showAtParam: null,
    className: "modal lc-modal fade",
  };

  componentDidMount() {
    const { showOnMount, showAfterDelay } = this.props;

    this.$modal = $(this.modal);

    if (showOnMount) {
      this.$modal.modal("show");
    }

    if (showAfterDelay) {
      setTimeout(() => {
        this.$modal.modal("show");
      }, showAfterDelay);
    }

    this.$modal.on("hidden.bs.modal", this.handleModalHidden);
    this.$modal.on("shown.bs.modal", this.handleModalShown);
    window.addEventListener("popstate", this.handleUrlChanges);
    this.handleUrlChanges();
  }

  handleModalShown = () => {
    if (!this.props.showAtParam) return;

    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.set("modalView", this.props.showAtParam);
    const newUrl = currentUrl.toString();
    window.history.pushState({}, "", newUrl);
  };

  handleModalHidden = () => {
    this.props.onHidden?.();
    if (!this.props.showAtParam) return;
    const url = new URL(window.location.href);
    url.searchParams.delete("modalView");
    window.history.pushState({}, "", url.toString());
  };

  handleUrlChanges = () => {
    if (!this.props.showAtParam) return;
    const url = new URL(window.location.href);
    const showModal =
      url.searchParams.get("modalView") === this.props.showAtParam
        ? "show"
        : "hide";
    this.$modal.modal(showModal);
  };

  render() {
    const {
      backdropStatic,
      children,
      closeIcon,
      footer,
      id,
      title,
      className,
      extraClassName,
    } = this.props;

    const fullClassName = `${className}${
      extraClassName ? ` ${extraClassName}` : ""
    }`;

    return ReactDOM.createPortal(
      <div
        className={fullClassName}
        id={id}
        data-backdrop={backdropStatic ? "static" : "true"}
        tabIndex="-1"
        role="dialog"
        ref={(el) => {
          this.modal = el;
        }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {title && (
              <div className="modal-header">
                {closeIcon && (
                  <button
                    type="button"
                    className="btn btn-xs btn-borderless"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span
                      className="lc-icon lc-icon-close lc-icon-xs"
                      aria-hidden="true"
                    />
                  </button>
                )}
                <div className="modal-title">{title}</div>
              </div>
            )}
            <div className="modal-body">{children}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>,
      document.querySelector("body")
    );
  }
}
