import React, { useEffect, useState } from "react";
import SignUpModal from "components/shared/SignUpModal";
import LcOAuth2 from "lib/shared/oauth2";

const GoogleOneTap = ({ countryOptions, countryCode, gdpr = false }) => {
  const [showModal, setShowModal] = useState(false);

  const skipFormAndCreateAccount = () => {
    $.ajax({
      data: {
        signup_origin: "google_one_tap",
        user: { simplified_signup: true, email_marketing_optin: true },
      },
      dataType: "json",
      method: "POST",
      url: "/",
      success: (_data, _textStatus, jqXHR) => {
        window.location.href = jqXHR.getResponseHeader("Location");
      },
    });
  };

  useEffect(() => {
    const handleCredentialResponse = (event) => {
      const { response } = event;
      window.LitCharts.modal.mutex.lock(); // Reserve slot ASAP for modal that will open on success.
      $.ajax({
        method: "POST",
        // TODO: Use more general strategy name than google_one_tap *but* still record if user signed up with one-tap or otherwise
        url: "/auth/google_one_tap/callback",
        data: response,
        success: (data, _textStatus, jqXHR) => {
          switch (data.state) {
            case "skip_form_and_create_account":
              skipFormAndCreateAccount();
              break;
            case "registration_in_progress":
              // Hide any existing modals in case they were opened while we waited for Google's response.
              window.LitCharts.modal.hideAll();
              // Show the sign up form modal so user can complete registration.
              setShowModal(true);
              break;
            case "signed_in": // A previously registered user has signed in.
            case "sign_in_elsewhere": // User needs to sign in on another page
              window.location.href = jqXHR.getResponseHeader("Location");
              break;
            default:
              console.log(
                "There was an error with Google One Tap, unrecognized state."
              );
          }
        },
        error: (jqXHR, _textStatus, _errorThrown) => {
          // Happens if Users::OmniauthCallbacksController#failure responds.
          window.LitCharts.modal.mutex.unlock();
          $("body").prepend(
            `<div class="lc-banner lc-banner-warning">${jqXHR.responseJSON.error}</div>`
          );
        },
      });
    };
    $(document).on(
      "googleIdOneTap:credentialResponse",
      handleCredentialResponse
    );

    const openPrompt = () => {
      // Docs: https://developers.google.com/identity/gsi/web/guides/use-one-tap-js-api
      // NOTE: We may need to revisit the commented out code, but isDisplayed() is not available
      // when Google migrates to FedCM.
      google.accounts.id.prompt((_notification) => {
        //if (notification.isDisplayed()) {
        $("html").addClass("google-one-tap-is-displayed");
        //} else {
        // $("html").removeClass("google-one-tap-is-displayed");
        //}
      });
    };
    LcOAuth2.initGoogle().then(openPrompt);

    // Returning useEffect cleanup function to remove any event listeners.
    return () => {
      $(document).off(
        "googleIdOneTap:credentialResponse",
        handleCredentialResponse
      );
    };
  }, []); // Empty dependencies array so this runs only once when component mounts.
  return (
    !!showModal && (
      <SignUpModal
        countryOptions={countryOptions}
        countryCode={countryCode}
        gdpr={gdpr}
        signupOrigin="google_one_tap"
      />
    )
  );
};

export default GoogleOneTap;
