import { toastNotify } from "helpers/toastNotify";
import React from "react";

// (paul) This allows us to set toast messages via Rails flash.
// Generally I don't like using `dangerouslySetInnerHTML` but that
// should only be set by us and not user input.

export default {
  initialize: () => {
    if (window.toastMessage) {
      toastNotify(
        window.toastMessage,
        window.toastLink && (
          <div dangerouslySetInnerHTML={{ __html: window.toastLink }} />
        )
      );
    }
  },
};
