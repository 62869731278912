import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/shared/Modal";

// SessionTimeoutModal

// The Devise :timeoutable module will silently timeout users for inactivity
// We show this modal to the user before that happens to give them the opportunity
// to stay signed in or sign out
// Session timeout is configured using the CustomTimeout concern.

// In practice, this means that on page load, this modal is hidden and sets a timer
// for 3 minutes before the session duration is up. After that, this modal will begin polling sessions#check_timeout
// every 10 seconds to find out how much time remains until session timeout
// Upon reaching 110 seconds or less, this modal will reveal itself and begin a 90 second timer:
//   => If the user clicks away from the modal or clicks the "Keep me logged in" button
//   their session timeout will be reset and they will stay logged in
//   => If the user does not perform any activity by the end of the timer, we send a redirect to
//   sessions#timeout which logs the user out and redirects them to the sign_in page with a flash message

const propTypes = {
  checkSessionTimeoutEvery: PropTypes.number,
  checkSessionTimeoutUrl: PropTypes.string.isRequired,
  keepAliveUrl: PropTypes.string.isRequired,
  signOutUrl: PropTypes.string.isRequired,
  sessionTimeoutUrl: PropTypes.string.isRequired,
  startPollingIn: PropTypes.number,
};

const selectors = {
  MAIN: "#session-timeout-modal",
};

const _debug = true;
const modalDuration = 90;

function SessionTimeoutModal(props) {
  const [remainingDuration, setRemainingDuration] = useState(modalDuration);
  const [isRevealed, setIsRevealed] = useState(false);
  const [tickInterval, setTickInterval] = useState();
  const [pollInterval, setPollInterval] = useState();
  const [sessionTimeoutTimer, setSessionTimeoutTimer] = useState();

  // on component mount, set timeout to start polling later
  useEffect(() => {
    pollCheckSessionTimeoutLater();
    window.addEventListener("lc3_session_timeout_modal_reveal", reveal);
    window.addEventListener(
      "lc3_session_timeout_modal_force_timeout",
      sessionTimeout
    );
  }, []);

  useEffect(() => {
    if (isRevealed === true) {
      clearPollInterval();
    } else {
      clearSessionTimeoutTimer();
      clearTickInterval();
    }
  }, [isRevealed]);

  const hide = () => {
    setIsRevealed(false);
    $(selectors.MAIN).modal("hide");
  };

  const reveal = () => {
    setIsRevealed(true);
    setTickInterval(setInterval(tick, 1000));
    debugLog(
      "Creating timer to session timeout in ",
      modalDuration,
      " seconds"
    );
    setSessionTimeoutTimer(setTimeout(sessionTimeout, modalDuration * 1000));
    $(selectors.MAIN).modal("show");
  };

  const clearTickInterval = () => {
    clearInterval(tickInterval);
    setTickInterval(null);
  };

  const tick = () => {
    setRemainingDuration((duration) => Math.max(duration - 1, 0));
  };

  const clearPollInterval = () => {
    clearInterval(pollInterval);
    setPollInterval(null);
  };

  // set timeout to start polling later
  const pollCheckSessionTimeoutLater = () => {
    debugLog(
      "Creating timer to start polling in ",
      props.startPollingIn,
      " seconds"
    );

    // setTimeout will execute immediately if the delay value exceeds the maximum.
    // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
    const maxDelay = 2147483647;
    const delay = props.startPollingIn * 1000;
    setTimeout(pollCheckSessionTimeout, Math.min(delay, maxDelay));
  };

  // poll to check remaining time since last server side activity
  const pollCheckSessionTimeout = () => {
    debugLog(
      "Creating interval to poll every ",
      props.checkSessionTimeoutEvery,
      " seconds"
    );
    const interval = setInterval(
      checkSessionTimeout,
      props.checkSessionTimeoutEvery * 1000
    );
    setPollInterval(interval);
  };

  // if remaining time until timeout is less than 110 seconds, reveal this modal
  const checkSessionTimeout = () => {
    debugLog("Polling checkSessionTimeout...");
    $.ajax({
      method: "get",
      url: props.checkSessionTimeoutUrl,
      success(data, _textStatus, _jqXHR) {
        debugLog("check_timeout response:", data);
        if (
          data > 0 &&
          data < modalDuration + props.checkSessionTimeoutEvery * 2
        ) {
          reveal();
        }
      },
    });
  };

  const clearSessionTimeoutTimer = () => {
    clearTimeout(sessionTimeoutTimer);
    setSessionTimeoutTimer(null);
  };

  const sessionTimeout = () => {
    debugLog("Requesting session timeout...");
    window.location.href = props.sessionTimeoutUrl;
  };

  const keepAlive = () => {
    $("html").attr("data-session-keep-alive", "pending");
    $.ajax({
      method: "post",
      url: props.keepAliveUrl,
      success(_data, _textStatus, _jqXHR) {
        hide();
        setRemainingDuration(modalDuration);
        pollCheckSessionTimeoutLater();
        $("html").attr("data-session-keep-alive", "success");
      },
      error() {
        $("html").attr("data-session-keep-alive", "error");
        $("body").prepend(
          '<div class="lc-banner lc-banner-warning">There was an error keeping you signed in.</div>'
        );
      },
    });
  };

  const debugLog = (...message) => {
    /* eslint-disable no-console */
    if (_debug) {
      console.log("[SessionTimeoutModal]", ...message);
    }
    /* eslint-enable no-console */
  };

  return (
    <Modal
      id={selectors.MAIN.slice(1)}
      showOnMount={false}
      onHidden={keepAlive}
      footer={false}
    >
      <div className="title-4">Still there?</div>
      <div className="body-2">
        You&apos;ve been inactive for a while and will be signed out in{" "}
        <b data-remaining-duration>
          {remainingDuration} {remainingDuration === 1 ? "second" : "seconds"}
        </b>{" "}
        to keep your account secure.
      </div>
      <button
        type="button"
        className="btn btn-block btn-lg btn-primary"
        onClick={hide}
      >
        Keep me signed in
      </button>
      <a
        className="btn btn-block btn-lg btn-default"
        data-method="delete"
        rel="nofollow"
        href={props.signOutUrl}
      >
        Sign out
      </a>
    </Modal>
  );
}

SessionTimeoutModal.propTypes = propTypes;

export default SessionTimeoutModal;
