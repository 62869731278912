import header from "views/shared/header/base";

// use this initializer for shared global views, i.e. sitewide header, footer, etc.
export default {
  initialize: () => {
    const views = [header];

    $().ready(() => {
      views.forEach((view) => {
        view.ready.call(view);
      });
    });
  },
};
